import { Col, Container, Row } from "reactstrap";

import WhatWeOfferList from "./MockData";

const WhatWeOffer = () => {

  return (
    <>
      <section className="what-we-offer">
        <h2 className="title">What We Offer!</h2>
        <Container className="offer-wrapper">
          <Row>
            {WhatWeOfferList.map((offer) => {
              const { id, img, title, desc, width, height } = offer;
              return (
                <Col lg={3} xl={3} key={id}>
                  <div className="offer-box">
                    <img src={img} loading="lazy" className="offer-img" alt="shipping" />
                    <h3 className="offer-title">{title}</h3>
                    <p className="offer-desc">{desc}</p>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default WhatWeOffer;
