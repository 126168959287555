import { getActionType } from '.';

export const USER_LOGIN = getActionType('USER_LOGIN');

export const USER_SIGNUP_REQUEST = 'USER_SIGNUP_REQUEST';

export const USER_LOGOUT = getActionType('USER_LOGOUT');

export const USER_VERIFY_EMAIL_REQUEST = 'USER_VERIFY_EMAIL_REQUEST';

export const USER_FORGOT_PASSWORD_REQUEST = 'USER_FORGOT_PASSWORD_REQUEST';

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST';

export const UPDATE_USER_PROFILE = getActionType('UPDATE_USER_PROFILE');

export const USER_CHANGE_PASSWORD_REQUEST = 'USER_CHANGE_PASSWORD_REQUEST';

export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA'