export const CART_PRODUCT_CREATE = 'CART_PRODUCT_CREATE';

export const CART_PRODUCT_DELETE = 'CART_PRODUCT_DELETE';

export const CART_PRODUCT_UPDATE = 'CART_PRODUCT_UPDATE';

export const GET_CART_PRODUCT_REQUEST = 'GET_CART_PRODUCT_REQUEST';

export const GET_CART_PRODUCT_COUNT = 'GET_CART_PRODUCT_COUNT';

export const GET_CART_PRODUCT_COUNT_SUCCESS = 'GET_CART_PRODUCT_COUNT_SUCCESS';

export const RESET_CART_PRODUCT_COUNT = 'RESET_CART_PRODUCT_COUNT';
