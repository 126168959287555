import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import "./banner.scss";

export const Banner = ({ bannerList = [] }) => {
  const [isActive, setIsActive] = useState(0);
  const navigate = useNavigate();

  const mainActiveImageWidth = 100 - (bannerList.length - 1) * (5)

  const moveNext = () => {
    const index = (isActive + 1) % bannerList.length;

    setIsActive(index);
  }

  const movePrev = () => {
    const index = isActive === 0 ? bannerList.length : (isActive - 1) % bannerList.length;

    setIsActive(index);
  }

  return (
    <section className="banner-2">
      <div className="swiper-button-prev" onClick={movePrev} />
      {
        bannerList.map((item, index) => (
          <div
            key={index}
            onClick={() => setIsActive(index)}
            className={isActive === index ? "slide active" : 'slide'}
            style={{
              backgroundImage: `url("${encodeURI(item.image)}")`,
              backgroundSize: 'cover',
              width: isActive === index ? `${mainActiveImageWidth}vw` : '5vw'
            }}
          >
            {isActive === index && <div>
              <div className="legend" dangerouslySetInnerHTML={{ __html: item.title }} />
              <button className="shop-now-btn" onClick={() => navigate(item.url)}>
                SHOP NOW
              </button>
            </div>}
          </div>
        ))
      }
      <div className="swiper-button-next" onClick={moveNext} />
    </section>
  )
}

export default Banner;