import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem } from "reactstrap";
import { NAVBAR_LIST } from "../Config";
import { TwoWheeler, FourWheeler } from "@assets/images";
import { useMediaQuery } from "@mui/material";

const NavContent = ({
    onClose
}) => {
    const isMobile = useMediaQuery('(max-width:767px)');
    return (
        <div className="navContent">
            <Nav navbar>
                {
                    Object.values(NAVBAR_LIST).map((nav, index) => {
                        return (
                            <NavItem key={index}>
                                {nav === NAVBAR_LIST.SEAT_COVERS &&
                                    <Dropdown toggle={() => { }}>
                                        <DropdownToggle data-toggle="dropdown" tag={Link} to="/products" className="nav-link-drop" nav disabled>
                                            {nav}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={onClose} tag={Link} to="/car-seat-covers" style={isMobile ? { width: 100 } : null}>
                                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                    <img src={FourWheeler} alt="four-wheeler" height={20} width={20} /> 4W
                                                </div>
                                            </DropdownItem>
                                            <DropdownItem divider className="divider" style={isMobile ? { width: 100 } : null} />
                                            <DropdownItem onClick={onClose} tag={Link} to="/two-wheeler-seat-covers" style={isMobile ? { width: 100 } : null}>
                                                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                    <img src={TwoWheeler} alt="two-wheeler" height={20} width={20} /> 2W
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                }
                                {nav === NAVBAR_LIST.MATS &&
                                    <Link className="nav-link" to="/autoform-car-mats" onClick={onClose}>
                                        {nav}
                                    </Link>
                                }
                                {nav === NAVBAR_LIST.ACCESSORIES &&
                                    <Link className="nav-link" to="/car-accessories" onClick={onClose}>
                                        {nav}
                                    </Link>
                                }
                                {nav === NAVBAR_LIST.WHY_AUTOFORM &&
                                    <Link className="nav-link" to="/about-us" onClick={onClose}>
                                        {nav}
                                    </Link>
                                }
                                {nav === NAVBAR_LIST.BLOGS &&
                                    <Link className="nav-link" to="/blogs" onClick={onClose}>
                                        {nav}
                                    </Link>
                                }
                            </NavItem>
                        );
                    })
                }
            </Nav>
        </div>
    )
}

export default NavContent