export const GET_DASHBOARD_BLOG_LIST_REQUEST = 'GET_DASHBOARD_BLOG_LIST_REQUEST';

export const GET_BLOG_LIST_REQUEST = 'GET_BLOG_LIST_REQUEST';

export const GET_BLOG_REQUEST = 'GET_BLOG_REQUEST';

export const GET_CATEGORY_BLOGS = 'GET_CATEGORY_BLOGS';

export const GET_CATEGORIES = 'GET_CATEGORIES'

export const GET_POPULAR_BLOGS = 'GET_POPULAR_BLOGS';

export const GET_HEADER_BLOG = 'GET_HEADER_BLOG';