import { review1, review2, review3, review4, review5, review6 } from "@assets/images";

const ClientList = [
  {
    description: "We had very good Experience right from Enquiry to fitting, Mr. Kapil has  helped always and responded immediately. Also Happy with product range and Quality.",
    clientName: 'Eighteen Travels',
    image: review1
  },
  {
    description: "The quality of Autoform products is excellent. The seat cover fitting is perfect for my XUV300. Special thanks to Mr Sumit, he was very humble and available all the time for my queries. Highly recommended.",
    clientName: 'Pankaj Mishra',
    image: review6
  },
  {
    description: "Extremely happy with the purchase. Quality of the product is excellent and the fitting is perfect for my Hyundai Venue. Special thanks to Mr Kapil, he was very humble and available all the time for my queries. Highly recommend 👍👍👍",
    clientName: 'Aseem Jain',
    image: review2
  },
  {
    description: "Really nice Experience.",
    clientName: 'Jagan Reddy',
    image: review5
  },
  {
    description: "I really appreciate their work n team. Excellent purchase for my honda brio. Thanks to team AutoForm. I got it what I was looking for.",
    clientName: 'Atul Kumar Saurabh',
    image: review3
  },
  {
    description: "Absolutely love the quality and feel of their products. I'm a car enthusiast, the proper one, and I don't trust or like any other brand for my cars.",
    clientName: 'Dolly Singh',
    image: review4
  }
]

export default ClientList;