export const VEHICLE_TYPE_ID = {
  TWO_WHEELER: 1,
  FOUR_WHEELER: 2
}

export const VEHICLE_OPTIONS = [
  {
    label: "Two Wheeler",
    value: VEHICLE_TYPE_ID.TWO_WHEELER
  },
  {
    label: "Four Wheeler",
    value: VEHICLE_TYPE_ID.FOUR_WHEELER
  }
]
