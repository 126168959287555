import { shipping, refund, support, premiumQuality } from "@assets/images";

const OfferData = [
  {
    id: 1,
    img: support,
    title: 'customer support',
    desc: 'Complete Customer Service with Guaranteed Satisfaction',
    width: 57,
    height: 74
  },
  {
    id: 2,
    img: refund,
    title: 'perfect fit',
    desc: 'Precise fitting with Great Quality',
    width: 58,
    height: 65
  },
  {
    id: 3,
    img: shipping,
    title: '100% customisation',
    desc: 'Designing and customisation can be made as per your requirement',
    width: 84,
    height: 55
  },
  {
    id: 4,
    img: premiumQuality,
    title: 'premium quality materials',
    desc: 'Premium Fabrics Provides Great style and Look',
    width: 75,
    height: 75
  }
]

export default OfferData;